const Hero = () => {
    return <div className="row">
        <div className="col-12 col-md-6">
            <h2 className="mt-5">Aprende a programar con React</h2>
            <hr />
            <h5 className="mb-4">Descubre nuestro curso en vivo gratuito para aprender a desarrollar aplicaciones con React.</h5>
            <p>Debido a la saturación que tenemos de <strong>frameworks javascript</strong>, es muy difícil aprender uno como es debido. El primer problema es decantarse por uno que cumpla con las expectativas que tenemos o solucione los problemas que nos planteamos.</p>
            <p>En este curso gratuito queremos darte la oportunidad de conocer cómo se programan aplicaciones web con una de las librerías más potentes del mercado. Con React, librería creada y apoyada por Facebook, llevaremos a otro nivel la creación de aplicaciones web a través de componentes.</p>
            <p>Revisa el calendario disponible dentro de esta web y las clases pasadas para <strong>no perderte nada.</strong></p>
            <a href="#contacto" className="btn cta">¿Quiéres saber más?</a>
        </div>
        <div className="col-12 col-md-6">
            <h2 className="mt-5">Emisión en VIVO</h2>
            <hr />
            <iframe
                src="https://player.twitch.tv/?channel=10minutosprogramando&parent=cursoreact.es&muted=true"
                width="100%"
                height="400"
                allowFullScreen={true}
                title="Twitch"
            >
            </iframe>
        </div>
    </div>
}

export default Hero;