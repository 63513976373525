import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import './Contacto.css';
import { collection, addDoc } from "firebase/firestore";
import db from '../../firebase/firebaseConfig';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacto = () => {

    const formik = useFormik({
        initialValues: { nombre: '', email: '', mensaje: '' },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required('Necesitamos saber cómo te llamas'),
            email: Yup.string().email('El correo tiene que ser decente').required('Necesitamos el email... por lo de mandar la newsletter...'),
        }),
        onSubmit: async (values, { resetForm }) => {
            const docRef = await addDoc(collection(db, "contacto"), values);
            Swal.fire({
                title: 'Muchas gracias 😁',
                text: 'Hemos recibido tus datos. Nos pondremos en contacto contigo si tenemos que comunicar algo.',
                confirmButtonText: 'Aceptar'
            });
            resetForm();
        }
    });

    return <>
        <div className="row" id="contacto">
            <div className="col-12 offset-0 col-md-8 offset-md-2">
                <h3 className="mt-5">Suscríbete a nuestra newsletter</h3>
                <hr />
            </div>
        </div>
        <div className="row">
            <div className="col-12 offset-0 col-md-4 offset-md-2 mb-4 mb-md-0">
                <p>Si te apetece que te contemos las últimas novedades sobre el curso, te puedes apuntar a nuestra newsletter. Prometemos 🙏 que únicamente vamos a usarla para hacer el bien.</p>
            </div>
            <div className="col-12 col-md-4">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-2">
                        <label className="form-label">
                            Nombre
                        </label>
                        <input type="text" className="form-control" name="nombre" onChange={formik.handleChange} value={formik.values.nombre} id="" />
                        {formik.touched.nombre && formik.errors.nombre ? (
                            <div className="error">{formik.errors.nombre}</div>
                        ) : null}
                    </div>
                    <div className="mb-2">
                        <label className="form-label">
                            Email
                        </label>
                        <input type="text" className="form-control" name="email" onChange={formik.handleChange} value={formik.values.email} id="" />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label className="form-label">
                            ¿Quiéres dejarnos un mensaje?
                        </label>
                        <textarea className="form-control" name="mensaje" rows="3" onChange={formik.handleChange} value={formik.values.mensaje}></textarea>
                    </div>
                    <button className="btn cta">Enviar</button>
                </form>
            </div>
            <ToastContainer />
        </div>
    </>

}

export default Contacto