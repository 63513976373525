import './App.css';
import ClasesPasadas from './components/ClasesPasadas/ClasesPasadas';
import Contacto from './components/Contacto/Contacto';
import CuentaAtras from './components/CuentaAtras/CuentaAtras';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import NavBar from './components/NavBar/NavBar';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="container">
        <Hero />
      </div>
      <div className="container-fluid">
        <div className="row mt-5 bg-gray">
          <div className="col-12 py-5">
            <CuentaAtras />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <ClasesPasadas />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="mt-5 pb-5 bg-gray">
          <div className="container">
            <Contacto />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
