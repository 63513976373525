import styled, { css } from "styled-components";

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    ${({ column }) => column &&
        css`
            flex-direction: column;
        `
    }
`

const FlexGrid = ({ children, column }) => {
    return <StyledDiv column={column}>{children}</StyledDiv>
}

export default FlexGrid;