import Countdown, { zeroPad } from 'react-countdown';
import styled from 'styled-components';
import FlexGrid from '../ui/FlexGrid';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es'

import './CuentaAtras.css';
import useGetDoc from '../../hooks/useGetDoc';

dayjs().locale('es').format()

const TimeCard = styled.div`
    border: 1px solid black;
    width: 100px;
    padding: 10px 20px;
    font-size: 2em;
    font-family: Montserrat;
    color: white;
    background-color: black;
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TimeText = styled.p`
    font-size: 16px;
    padding: 0;
    color: #00fff0;
`

const renderer = ({ days, hours, minutes, seconds }) => (
    <>
        <TimeCard>
            <span>{zeroPad(days)}</span>
            <TimeText>Días</TimeText>
        </TimeCard>
        <TimeCard>
            <span>{zeroPad(hours)}</span>
            <TimeText>Horas</TimeText>
        </TimeCard>
        <TimeCard>
            <span>{zeroPad(minutes)}</span>
            <TimeText>Minutos</TimeText>
        </TimeCard>
        <TimeCard>
            <span>{zeroPad(seconds)}</span>
            <TimeText>Segundos</TimeText>
        </TimeCard>
    </>
);

const CuentaAtras = () => {

    const nextClassTopic = useGetDoc('config', '44Yw557iV9K95539tEqb');
    const nextClass = useGetDoc('config', 'iTlmTEA9ZjCppdd8bRbu');

    return <>
        <h4 className="text-center ">Próxima clase</h4>
        {nextClass &&
            <>
                <h5 className="text-center mb-4 next-class">
                    {dayjs(nextClass.value.toDate()).locale('es').format('dddd DD-MM-YYYY HH:mm').toUpperCase()}
                </h5>
                <FlexGrid>
                    <Countdown date={nextClass.value.toDate()} renderer={renderer} />
                </FlexGrid>
            </>
        }
        {nextClassTopic &&
            <h5 className="text-center mt-4 next-class-topic">
                {nextClassTopic.value.toUpperCase()}
            </h5>
        }
    </>
}

export default CuentaAtras;