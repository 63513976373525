import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';

hotjar.initialize(2649847, 6);

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="keywords" content="desarrollo, web, react, frameworks, curso, gratis,programación, mern, aprende, desde, cero, twitch, directo, vivo, español" />
      <meta name="description" content="Curso de ReactJS totalmente gratis para que aprendas a programar desde cero aplicaciones web" />
      <meta name="author" content="Mario Girón" />
      <meta name="robots" content="index, nofollow" />
      <title>CursoReact.es - Aprende a programar con React gratis</title>
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
