import db from '../firebase/firebaseConfig';
import { getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from 'react';

const useGetDoc = (table, key) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const getData = async () => {
            const querySnapshot = await getDoc(doc(db, table, key));
            setData(querySnapshot.data());
        }
        getData();
    }, [table, key]);

    return data;
}

export default useGetDoc;