import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import YouTube from "react-youtube";
import db from '../../firebase/firebaseConfig';
import FlexGrid from "../ui/FlexGrid";

import './ClasesPasadas.css'

const ClasesPasadas = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const getVideos = async () => {
            const videosRef = collection(db, 'videos');
            const q = query(videosRef, orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setVideos((videos) => [...videos, doc.data()]);
            });
        }
        getVideos();
    }, []);

    return <>
        <h3>Clases anteriores</h3>
        <hr />
        <FlexGrid>
            {videos.map((video) => (
                <FlexGrid column key={video.video_id}>
                    <YouTube
                        videoId={video.video_id}
                        opts={{
                            width: '340',
                            height: '200'
                        }}
                        className="video"
                    ></YouTube>
                    <p>{video.title}</p>
                </FlexGrid>
            ))}
        </FlexGrid>
    </>
}

export default ClasesPasadas;