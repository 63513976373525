import useGetDoc from '../../hooks/useGetDoc';
import './NavBar.css';

const NavBar = () => {

    const isLive = useGetDoc('config', 'EgKC2t5DPJg9kBWlnRRz');

    return <nav className="navbar navbar-dark bg-dark">
        <div className="container">
            <a className="navbar-brand">
                <img src="/images/logo_large.png" alt="" className="logo" />
            </a>
            <div className="ms-auto mr-5">
                {(isLive && isLive.value && (
                    <img src="/images/live.png" className="live_logo" />
                ))}
            </div>
        </div>
    </nav>
}

export default NavBar;